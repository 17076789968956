import { ApiRequest } from "./base";

const apiRoute = "recording";

export const RecordAMessageApi = {
	async getRecording(id, hash) {
		try {
			const { recording } = await ApiRequest(`${apiRoute}/${id}/${hash}`, "GET");
            const { location, date_made, sender_name, type, path_ci } = recording;
            return { location, date_made, sender_name, type, path_ci };
		} catch (error) {
			console.error(`Failed to get recording: ${error}`);
			return error;
		}
	},
	async uploadRecording(type = 'video', content) {
		try {
			const response = await ApiRequest(`${apiRoute}/save-recording`, "POST", {
				type,
				content
			});
			return response;
		} catch (error) {
			console.error(`Failed to upload recording: ${error}`);
			return error;
		}
	}
};
