import React from "react";

export const ProductDescription = ({ shortDescription, fullDescription, productName = "", id = "", sku = "" }) => {
	return (
		<section className="description-section">
			<h1 className="product-name">{productName ?? " "}</h1>
			{shortDescription && <strong className="short-description">{shortDescription}</strong>}
			{fullDescription &&
				(fullDescription.includes("</") ? (
					<p
						className="description"
						dangerouslySetInnerHTML={{ __html: fullDescription }}
					></p>
				) : (
					<p className="description">{fullDescription}</p>
				))}

			<div className="product-debug-information">{`${id} - ${sku}`}</div>
		</section>
	);
};
