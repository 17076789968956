import { useEffect, useState } from "react";
import { useAppStore } from "../stores/AppStore";

export const DebugHandler = () => {
	const [codeEntered, setCodeEntered] = useState(false);

	const konamiCode = ["ArrowUp", "ArrowUp", "ArrowDown", "ArrowDown", "ArrowLeft", "ArrowRight", "ArrowLeft", "ArrowRight", "b", "a"];

	let konamiIndex = 0;

	const handleKeydown = (event) => {
		if (event.key === konamiCode[konamiIndex]) {
			konamiIndex++;
			if (konamiIndex === konamiCode.length) {
				setCodeEntered(true);
				konamiIndex = 0;
				alert("Konami Code entered! Debug mode toggled.");
				useAppStore.getState().ToggleDebugMode();
			}
		} else {
			konamiIndex = 0;
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", handleKeydown);
		return () => {
			window.removeEventListener("keydown", handleKeydown);
		};
	}, []);
};

export default DebugHandler;
