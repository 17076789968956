import React, { useState, useEffect } from "react";
import Config from "../Config";
import Field from "./Field";

import { useCartStore } from "../stores/CartStore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faXmark } from "@fortawesome/pro-regular-svg-icons";

import "../assets/scss/components/_credit.scss";

export const Credit = ({ creditAvailable }) => {
	const [creditAmount, setCreditAmount] = useState(0);
	const [customCredit, setCustomCredit] = useState(false);
	const [creditApplied, setCreditApplied] = useState(false);

	const { ApplyCredit, totals } = useCartStore(({ ApplyCredit, totals }) => {
		return { ApplyCredit, totals };
	});

	useEffect(() => {
		if (totals && totals.creditTotal) {
			setCreditAmount(parseFloat(totals.creditTotal).toFixed(2));
			setCreditApplied(true);
		}
	}, [totals]);

	return (
		<section className="credit-amount-container">
			<strong className="section-title">
				You Have {Config.currency}
				{parseFloat(creditAvailable).toFixed(2)} Available Credit Extra!
			</strong>
			<p className="section-description">Select an amount below or enter your own value to use the credit for this order.</p>

			<div className="credit-amount-wrapper">
				<div className={`row align-items-stretch credit-amount-track no-wrap ${creditApplied ? "credit-applied" : ""}`}>
					<div className="credit-amounts row align-items-stretch justify-content-center col-12">
						{creditAvailable >= 5 && (
							<div
								className={`credit-amount col-fluid ${customCredit === false && creditAmount === 5 ? "active" : ""}`}
								onClick={() => {
									setCreditAmount(parseFloat(creditAmount !== 5 ? 5 : 0).toFixed(2));
									setCustomCredit(false);
								}}
							>
								{Config.currency}5
							</div>
						)}
						{creditAvailable >= 10 && (
							<div
								className={`credit-amount col-fluid ${customCredit === false && creditAmount === 10 ? "active" : ""}`}
								onClick={() => {
									setCreditAmount(parseFloat(creditAmount !== 10 ? 10 : 0).toFixed(2));
									setCustomCredit(false);
								}}
							>
								{Config.currency}10
							</div>
						)}

						{creditAvailable >= 15 && (
							<div
								className={`credit-amount col-fluid ${customCredit === false && creditAmount === 15 ? "active" : ""}`}
								onClick={() => {
									setCreditAmount(parseFloat(creditAmount !== 15 ? 15 : 0).toFixed(2));
									setCustomCredit(false);
								}}
							>
								{Config.currency}15
							</div>
						)}
						<div className={`credit-amount custom-credit-amount col-6 ${customCredit === true ? "active" : ""}`}>
							<Field
								type={"number"}
								min={1}
								max={creditAvailable}
								label={`Enter ${Config.currency}1 - ${Config.currency}${parseFloat(creditAvailable)}`}
								value={creditAmount}
								onChange={(e) => {
									const amount = parseFloat(e.target.value ?? 0).toFixed(2);

									if (Number.isNaN(amount)) {
										setCreditAmount(0);
									}

									if (amount === 0) {
										setCustomCredit(false);
										setCreditAmount(0);
									}

									if (amount > 0) {
										setCustomCredit(true);
										setCreditAmount(parseFloat(amount));
									}

									if (amount > parseFloat(creditAvailable)) {
										setCreditAmount(parseFloat(creditAvailable));
									}

									if (amount < 0) {
										setCreditAmount(0);
									}
								}}
								onClick={() => {
									setCustomCredit(true);
								}}
								onKeyDown={(e) => {
									const { value } = e.target;

									const float = value.match(/([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s);
									setCreditAmount(float[0] ?? 0);
								}}
							/>
						</div>
					</div>
					<div className="credit-selected row align-items-stretch justify-content-space-between col-12">
						<div className="col-12">
							<div className="credit-applied-details">
								You are using {Config.currency}
								{creditAmount} credit
								<div
									className="cancel-button"
									onClick={() => {
										ApplyCredit(0);
										setCreditAmount(0);
										setCustomCredit(false);
										setCreditApplied(false);
									}}
								>
									<FontAwesomeIcon icon={faXmark} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{creditAmount && creditAmount > 0 && creditAmount <= creditAvailable && !creditApplied ? (
				<div
					className="col-12 btn credit-button"
					onClick={() => {
						ApplyCredit(creditAmount);
						setCreditApplied(true);
					}}
				>
					Apply {Config.currency}
					{parseFloat(creditAmount).toFixed(2)} Credit
				</div>
			) : null}
		</section>
	);
};
