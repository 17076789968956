import { ApiRequest } from "./base";

const apiRoute = "user/wishlist";

export const WishlistApi = {
	async get() {
		return ApiRequest(`${apiRoute}`, "GET");
	},
	async add(data) {
		const { wishlist } = await ApiRequest(`${apiRoute}/add`, "POST", data);
		return wishlist;
	},
	async remove(data) {
		const { wishlist } = await ApiRequest(`${apiRoute}/remove`, "POST", data);
		return wishlist;
	}
};
