const GetOrdinal = (d) => {
	if (d > 3 && d < 21) return "th";
	switch (d % 10) {
		case 1:
			return "st";
		case 2:
			return "nd";
		case 3:
			return "rd";
		default:
			return "th";
	}
};

export const DateInfo = (date, ordinal = true, is24HourFormat = true) => {
	const d = new Date(date);

	const monthNames = {
		short: {
			1: "Jan",
			2: "Feb",
			3: "Mar",
			4: "Apr",
			5: "May",
			6: "Jun",
			7: "Jul",
			8: "Aug",
			9: "Sep",
			10: "Oct",
			11: "Nov",
			12: "Dec"
		},
		long: {
			1: "January",
			2: "February",
			3: "March",
			4: "April",
			5: "May",
			6: "June",
			7: "July",
			8: "August",
			9: "September",
			10: "October",
			11: "November",
			12: "December"
		}
	};

	const dayNames = {
		short: {
			0: "Sun",
			1: "Mon",
			2: "Tue",
			3: "Wed",
			4: "Thu",
			5: "Fri",
			6: "Sat"
		},
		long: {
			0: "Sunday",
			1: "Monday",
			2: "Tuesday",
			3: "Wednesday",
			4: "Thursday",
			5: "Friday",
			6: "Saturday"
		}
	};

	return {
		values: {
			year: d.getFullYear(),
			month: d.getMonth() + 1,
			day: d.getDate(),
			hours: d.getHours(),
			minutes: d.getMinutes()
		},
		pretty: {
			long: {
				year: d.getFullYear(),
				month: monthNames.long[d.getMonth() + 1],
				date: d.getDate() + (ordinal ? GetOrdinal(d.getDate()) : ""),
				day: dayNames.long[d.getDay()],
				hours: !is24HourFormat && d.getHours() > 12 ? d.getHours() - 12 : d.getHours(),
				minutes: String(d.getMinutes()).length === 1 ? "0" + d.getMinutes() : d.getMinutes(),
				hourSuffix: d.getHours() >= 12 ? "PM" : "AM"
			},
			short: {
				year: d.getFullYear(),
				month: monthNames.short[d.getMonth() + 1],
				date: d.getDate() + (ordinal ? GetOrdinal(d.getDate()) : ""),
				day: dayNames.short[d.getDay()],
				hours: !is24HourFormat && d.getHours() > 12 ? d.getHours() - 12 : d.getHours(),
				minutes: String(d.getMinutes()).length === 1 ? "0" + d.getMinutes() : d.getMinutes(),
				hourSuffix: d.getHours() >= 12 ? "PM" : "AM"
			}
		}
	};
};

export const DateDifference = (date1, date2 = "today") => {
	if (date2 === "today") {
		date2 = new Date();
	} else {
		date2 = new Date(date2);
	}

	const diff = new Date(date1).getTime() - date2.getTime();
	const days = Math.floor(diff / (1000 * 60 * 60 * 24));
	const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((diff % (1000 * 60)) / 1000);

	return { days, hours, minutes, seconds };
};
