import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";

import { useCartStore } from "../stores/CartStore";
import { useProductStore } from "../stores/ProductStore";

import "../assets/scss/components/_mini-cart-item.scss";

import { Image } from "./Image";

import { DateInfo } from "../utilities/DateUtils";

import Config from "../Config";

export const MiniCartItem = ({ image, productName, size, price, cartKey, addons, deliveryDate, deliveryType, deliveryAddress, messageCard, removeButtonShown = true, messageCardType = "standard", personalisedCardID = "", personalisedImageShown = false, videoMessage = false }) => {
	const { RemoveFromCart } = useCartStore(({ RemoveFromCart }) => ({ RemoveFromCart }));
	const { availableAddons } = useProductStore(({ availableAddons }) => ({ availableAddons }));

	const [dateInformation, setDateInformation] = useState(DateInfo(deliveryDate, false));

	useEffect(() => {
		setDateInformation(DateInfo(deliveryDate, true));
	}, [deliveryDate]);

	return (
		<div className="basket-item row justify-content-space-between align-items-start">
			<div className="image col-2">
				<Image
					src={image?.replace(".local", ".co.uk")}
					alt={productName}
				/>
			</div>
			<div className="details col-fluid">
				<strong className="title">{productName}</strong>
				<div className="options">
					<div className="option">{size}</div>
					{deliveryDate !== "" && <div className="option">{`${dateInformation.pretty.short.date} ${dateInformation.pretty.short.month} Delivery`}</div>}
					{deliveryType && <div className="option">{deliveryType}</div>}
				</div>

				{deliveryAddress && deliveryAddress !== "" && (
					<div className="address options">
						<div className="option">Delivering To: {deliveryAddress}</div>
					</div>
				)}

				{messageCard && messageCard !== "" && (
					<div className="message-card options">
						<div className="option">With The Gift Card: "{messageCard}"</div>
					</div>
				)}

				{personalisedCardID && personalisedCardID !== "" && (
					<div className="message-card options">
						<div className="option">
							With A Personalised Gift Card{" "}
							{personalisedImageShown && (
								<img
									className="personalised-card-preview"
									src={`${Config.personalisedThumbUrl}/${personalisedCardID}.jpg`}
								/>
							)}
						</div>
					</div>
				)}

				{videoMessage && (
					<div className="message-card options">
						<div className="option">With A Video Message</div>
					</div>
				)}

				{typeof addons !== "undefined" && Object.keys(addons).length !== 0 && typeof availableAddons !== "undefined" && Object.keys(availableAddons).length !== 0 && (
					<div className="addons options">
						{Object.keys(addons).length === 1 ? "Addon: " : "Addons: "}
						{Object.keys(addons).map((addonIndex) => {
							const addon = addons[addonIndex];

							let addonName = "";
							if (addon.addon_name) {
								addonName = addon.addon_name;
							} else if (typeof addon !== "object") {
								addonName = availableAddons[addon].pretty_name;
							} else {
								const addonId = addon.id;
								let foundAddon = null;

								if (availableAddons[addonId]) {
									foundAddon = availableAddons[addonId];
								}

								addonName = foundAddon.pretty_name;
							}

							if (addonName.substring(0, 2) === "pw") {
								delete addons[addonIndex];
								return;
							}

							addonName = Object.keys(addons).length - addonIndex > 2 ? addonName + ", " : addonName;
							addonName = Object.keys(addons).length > 1 && parseInt(addonIndex) === Object.keys(addons).length - 1 ? " & " + addonName : addonName;
							return addonName;
						})}
					</div>
				)}
			</div>
			<div className="col-2 actions">
				<span className="price">£{parseFloat(price).toFixed(2)}</span>
				{removeButtonShown && (
					<div className="remove-button">
						<FontAwesomeIcon
							icon={faTrashCan}
							onClick={() => {
								RemoveFromCart(cartKey);
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
};
