import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { ActionButtons } from "./ActionButtons";
import { ProductImage } from "./ProductImage";
import { Placeholder } from "./Placeholder";
import { AvailableFrom } from "./AvailableFrom";

import "../assets/scss/components/_product-listing.scss";

import { useProductStore } from "../stores/ProductStore";
import { useCategoriesStore } from "../stores/CategoriesStore";
import { useUserStore } from "../stores/UserStore";

export const ProductListing = forwardRef(({ id, info, size, sort, bogus = false, lazy = false }, ref) => {
	var overlay_type = "";
	// overlay_type = sort === 0 ? "sellingFast" : overlay_type;
	// overlay_type = sort === 1 ? "sale" : overlay_type;

	const { tags } = useCategoriesStore(({ tags }) => {
		return { tags };
	});

	const { wishlistInfo } = useUserStore(({ wishlistInfo }) => {
		return { wishlistInfo };
	});

	// const [info, setInfo] = useState({});
	const [stockClass, setStockClass] = useState("in-stock");
	const [isWishlisted, setIsWishlisted] = useState(false);

	const initialClassList = useMemo(() => {
		return ["product-listing", "row", "column"];
	}, [id]);

	const [isProductShown, setIsProductShown] = useState(true);

	let classList = initialClassList.join(" ");

	Object.keys(size).forEach((specificSize) => {
		classList += " col" + (specificSize === "base" ? "" : "-" + specificSize) + "-" + size[specificSize];
	});

	useEffect(() => {
		if (wishlistInfo[id]) {
			setIsWishlisted(true);
		} else {
			setIsWishlisted(false);
		}
	}, [wishlistInfo, id]);

	useEffect(() => {
		if (!bogus) {
			//check the stock level and apply a class
			const firstDate = new Date(info.first_available_delivery).getTime();
			const time = new Date().getTime();

			if ((info && (info.stock === 0 || firstDate === "" || firstDate < time)) || !info) {
				setStockClass("out-of-stock");
			}
		}
	}, [bogus, info]);

	useEffect(() => {
		let valid = true;

		if (info && tags) {
			//we have tags
			if (tags?.stems?.length === 0 && tags?.colours?.length === 0) {
				setIsProductShown(valid);
				return;
			}

			if (info.product_meta?.keywords) {
				if (tags.stems.length > 0) {
					if (info.product_meta.keywords !== "") {
						tags.stems.forEach((stem) => {
							if (!info.product_meta.keywords.includes(stem)) {
								valid = false;
							}
						});
					} else {
						valid = false;
					}
				}

				if (tags.colours.length > 0) {
					if (info.product_meta.keywords !== "") {
						tags.colours.forEach((colours) => {
							if (!info.product_meta.keywords.includes(colours)) {
								valid = false;
							}
						});
					} else {
						valid = false;
					}
				}
			} else {
				valid = false;
			}
		}
		setIsProductShown(valid);
	}, [tags, info]);

	let overlayInfo = {};

	if (info && !bogus) {
		if (info.price_msrp && info.price_msrp !== 0 && info.price_retail) {
			overlayInfo = {
				type: overlay_type,
				value: (parseFloat(info.price_msrp - info.price_retail) / parseFloat(info.price_msrp)) * 100
			};
		}
	}

	return info && !bogus ? (
		<div
			className={`${classList} ${stockClass} ${isProductShown ? "" : "filtered"} ${isWishlisted ? "wishlisted" : ""}`}
			ref={ref}
			style={{
				"--data-retail": info.price_retail ? Math.ceil(info.price_retail) * 100 : 0,
				"--data-sort": sort ?? "",
				"--data-product-id": id ?? 0
			}}
		>
			<Link
				to={`/${info.product_slug}`}
				aria-label={info.products_name}
			>
				<ProductImage
					image={info.images ? info.images[0].location : ""}
					overlayType={overlayInfo.type ? overlayInfo.type : ""}
					overlayValue={overlayInfo ? overlayInfo.value : ""}
					alt={info.products_name ?? ""}
					lazy={lazy}
				/>

				<div className="product-information row column col-fluid">
					<div className="top-line row align-content-center justify-content-space-between">
						<span className="product-name">{info.products_name}</span>
						<div className="product-pricing">
							{info?.has_sizes === "Yes" && <span className="small">from</span>} &pound;{info.price_retail ? parseFloat(info.price_retail).toFixed(2) : ""}
						</div>
					</div>

					<div className="product-quote-container">
						<div className="product-quote">
							<>{info.quote ? <>{info.quote}</> : info.short_description ? <>{info.short_description.substr(0, 100).trim() + "..."}</> : info.full_description && <>{info.full_description.substr(0, 100).trim() + "..."}</>}</>
						</div>
					</div>

					<div className="delivery-callout">
						<AvailableFrom date={info.stock > 0 && info.first_available_delivery !== "" ? info.first_available_delivery : false} />
					</div>
				</div>
			</Link>
			<ActionButtons
				rating={info.review_average ?? 0}
				product_id={id ?? 0}
			/>
		</div>
	) : (
		<Link className={`${classList} ${stockClass}`}>
			<ProductImage image={""} />

			<div className="product-information row column col-fluid">
				<div className="top-line row align-content-center justify-content-space-between">
					<Placeholder
						check={false}
						style={{
							height: "100%",
							flex: "1"
						}}
					>
						<h3 className="product-name">Bogus</h3>
					</Placeholder>

					<div className="product-pricing">
						<span className="small">from</span>

						<Placeholder
							width="55px"
							check={false}
							style={{
								display: "inline-block"
							}}
						>
							&pound;0.00
						</Placeholder>
					</div>
				</div>

				<Placeholder
					check={false}
					width="100%"
					class="product-quote-container"
				>
					<div className="product-quote"></div>
				</Placeholder>

				<Placeholder
					check={false}
					style={{
						flex: "1 1"
					}}
					width="100%"
				>
					<div className="delivery-callout">&nbsp;</div>
				</Placeholder>

				<Placeholder
					check={false}
					style={{
						flex: "1 1"
					}}
					width="100%"
				>
					<ActionButtons rating={0} />
				</Placeholder>
			</div>
		</Link>
	);
});
