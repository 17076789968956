import { create } from "zustand";
import { log } from "./helpers";

import { persist, devtools } from "zustand/middleware";

import { CartApi } from "../api";

import { useAppStore } from "./AppStore";
import { useProductStore } from "./ProductStore";

const CartBroadcast = new BroadcastChannel("cart");

CartBroadcast.onmessage = (event) => {
	if (useCartStore.getState().cart !== event.data) {
		if (useAppStore.getState().isDebug) {
			console.log("CartStore.js - Cart changed, updating");
		}
		useCartStore.getState().SetCart(event.data);
	}
};

window.klaviyo = window.klaviyo || [];

const TrackKlaviyoCart = (totals, cart = []) => {
	console.log("CartStore.js - Tracking Klaviyo cart", totals, cart);
	const products = cart.map((item) => {
		const productSizeKey = item.product_info.sizes.findIndex((size) => size.size === item.size);
		const productSizePrice = productSizeKey !== -1 ? item.product_info.sizes[productSizeKey].price : 0;

		return {
			name: item.product_info.products_name,
			sku: item.product_info.sku,
			size: item.size,
			quantity: item.quantity,
			price: item.product_info.price_retail + productSizePrice,
			price_msrp: item.product_info.price_msrp + productSizePrice,
			category: item.product_info.product_type ?? "",
			product_image_url: item.product_info.image,
			product_url: item.product_info.product_url,
			delivery_date: item.deliverydate,
			delivery_method: item.deliverymethod
		};
	});

	return {
		totals,
		products
	};
};

const useCartStore = create(
	persist(
		devtools(
			log((set, get) => ({
				cart: null,
				discountCode: "",
				discountMessage: "",
				discountStatus: "none",
				totals: {},
				miniCart: false,
				SetCart: (cart) => {
					set({ cart });

					get().UpdateTotals();

					return cart;
				},
				GetCart: async () => {
					let cart = await CartApi.GetCart();
					set({ cart });
					await get().UpdateTotals();
					return cart;
				},
				UpdateTotals: async () => {
					const cartTotals = await CartApi.GetTotals();

					if (useAppStore.getState().isDebug) {
						console.log("CartStore.js - Updating cart totals", cartTotals);
					}

					const totals = {
						productTotal: parseFloat(cartTotals.product_total ?? 0),
						addonTotal: parseFloat(cartTotals.addon_total ?? 0),
						grandTotal: parseFloat(cartTotals.grand_total ?? 0),
						deliveryTotal: parseFloat(cartTotals.delivery_total ?? 0),
						discountTotal: parseFloat(cartTotals.discount_total ?? 0),
						creditTotal: parseFloat(cartTotals.credit_to_use ?? 0)
					};

					set({ totals });
				},
				AddToCart: async (info, showCart = true) => {
					const { new_key, cart } = await CartApi.AddToCart(info);

					const updatedCart = await get().UpdateProductInCart(new_key, info);

					get().SetCart(updatedCart);

					get().ToggleMiniCart(showCart);
					CartBroadcast.postMessage(get().cart);

					try {
						const klaviyoCart = TrackKlaviyoCart(get().totals, updatedCart);
						window.klaviyo.push(["track", "Started Checkout", klaviyoCart]);
					} catch (error) {
						console.log("Failed to track cart in Klaviyo:", error);
					}

					return cart;
				},
				CheckCartItems: async () => {
					const cart = await get().GetCart();

					if (cart.length > 0) {
						cart.forEach(async (item, key) => {
							try {
								useProductStore
									.getState()
									.GetProductInfo(item.product_info.id, true)
									.then((prod) => {
										if (item?.deliverydate) {
											if (new Date(item.deliverydate) < new Date()) {
												if (prod?.delivery?.upcoming_dates?.length > 0) {
													get().UpdateProductInCart(key, {
														attr_deliverydate: prod.delivery.upcoming_dates[0]
													});
												}
											}
										}
									});
							} catch (error) {
								console.log("Failed to get product info for item:", error);
							}

							//delivery date

							//stock
						});
					}
				},
				RemoveFromCart: async (cartKey) => {
					try {
						await CartApi.RemoveFromCart(cartKey);
					} catch (error) {
						console.error("Failed to remove item from cart:", error);
					}
					CartBroadcast.postMessage(get().cart);
					return get().GetCart();
				},
				ClearCart: async () => {
					await CartApi.ClearCart();
					CartBroadcast.postMessage(get().cart);
					set({ discountMessage: "", discountCode: "", discountStatus: "none" });
					return get().GetCart();
				},
				ToggleMiniCart: (force_status = null) => {
					if (force_status !== null) {
						set({ miniCart: force_status });
					} else {
						set({ miniCart: !get().miniCart });
					}

					return get().miniCart;
				},
				UpdateProductInCart: async (cartId, info) => {
					if (useAppStore.getState().isDebug) {
						console.log(`CartStore.js - Updating item ${cartId} in cart`, info);
					}

					await CartApi.UpdateProductInCart(cartId, info);
					CartBroadcast.postMessage(get().cart);
					return get().GetCart();
				},
				SetDiscountCode: (discountCode) => {
					set({ discountCode });
				},
				ApplyDiscount: async (discountCode) => {
					try {
						const { discount } = await CartApi.ApplyDiscount(discountCode);

						if (useAppStore.getState().isDebug) {
							console.log(`CartStore.js - Adding discount code ${discountCode} to cart`, discount);
						}

						if (discount?.code) {
							discount.message = discount.message.replace(/<[^>]*>?/gm, "");
							discount.message = discount.message.replace("&pound;", "£");
							set({ discountMessage: discount.message, discountStatus: "success", discountCode: discount.code });
						} else {
							set({ discountStatus: "none", discountCode: "", discountMessage: "" });
						}
					} catch (error) {
						if (useAppStore.getState().isDebug) {
							console.error(`CartStore.js - Discount code failed to apply: ${error.message}`);
						}
						set({ discountMessage: error, discountCode: "", discountStatus: "error" });
					}
					await get().GetCart();
				},
				ApplyCredit: async (creditAmount) => {
					const { credit } = await CartApi.ApplyCredit(creditAmount);

					if (credit.success) {
						if (useAppStore.getState().isDebug) {
							console.log(`CartStore.js - Adding ${credit.amount_applied} credit to cart, requested ${credit.amount_requested}`);
						}
						await get().UpdateTotals();
					} else {
						console.error(`CartStore.js - Credit failed to add`);
					}
				}
			}))
		),
		{
			name: "cart-storage"
		}
	)
);

export { useCartStore };
