import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { ConfigApi } from "../api";
import Config from "../Config";

import { useCartStore } from "./CartStore";

import { DateDifference } from "../utilities/DateUtils";

const useAppStore = create(
	persist(
		devtools((set, get) => ({
			sessionId: null,
			cacheVersion: null,
			isDebug: false,
			websiteBanner: null,
			peakConfigs: null,
			staleCheck: false,
			init: async () => {
				const configData = await ConfigApi.GetConfig();

				const cacheVersion = Config.cacheVersion;

				set((prev) => ({
					...prev,
					sessionId: configData.session_id,
					websiteBanner: configData.promo_banner,
					peakConfigs: configData.peak_configs,
					cacheVersion: prev.cacheVersion ? prev.cacheVersion : cacheVersion,
				}));

				if (1 === 1) {
					const staleCheck = get().staleCheck;
					const staleTiming = 30; //minutes
					let newCheck = true;

					if (staleCheck) {
						const diff = DateDifference(new Date(), staleCheck);
						if (diff.hours > 0 || diff.minutes > staleTiming || diff.days > 0) {
							//we are stale
							if (useAppStore.getState().isDebug) {
								console.log("AppStore.js - Stale state is detected, let's update.");
								useCartStore.getState().CheckCartItems();
							}
						} else {
							newCheck = false;
						}
					}

					if (newCheck) {
						console.log("AppStore.js - Setting stale check for current time.");
						set((prev) => ({ ...prev, staleCheck: new Date() }));
					}
				}

				//check to see if cookie is set
				if (!document.cookie.includes("PHPSESSID")) {
					document.cookie = `PHPSESSID=${configData.session_id}`;
				}

				//check to see if we have items in the cart and their status
				const cart = useCartStore.getState().cart;
				if (cart && cart.length > 0) {
					useCartStore.getState().CheckCartItems();
				}
			},
			getSessionId: () => get().sessionId,
			getWebsiteBanner: () => get().websiteBanner,
			resetLocalStorage: () => {
				localStorage.clear();
			},
			ToggleDebugMode: () => {
				set((prev) => ({ ...prev, isDebug: !prev.isDebug }));
			},
			cacheVersionValidation: () => {
				if (Config.cacheVersion !== get().cacheVersion) {
					console.log("Version mismatch, clearing local storage.");
					get().resetLocalStorage();
					set((prev) => ({ ...prev, cacheVersion: Config.cacheVersion }));
					window.location.reload();
				}
			},
		})),
		{
			name: "app-storage",
		}
	)
);

export { useAppStore };
