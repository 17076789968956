import React, { useState, useEffect, useRef } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMagnifyingGlass, faClose } from "@fortawesome/pro-regular-svg-icons";

import "../assets/scss/components/_search-bar.scss";

export const SearchBar = () => {
	const navigate = useNavigate();
	const [searchBar, setSearchBar] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const inputRef = useRef(null);

	const handleButton = () => {
		if (searchValue !== "") {
			navigate(`/search/${searchValue}`);
		} else {
			setSearchBar((prev) => !prev);
		}
	};

	const location = useLocation();

	useEffect(() => {
		setSearchBar(false);
		setSearchValue("");
	}, [location]);

	useEffect(() => {
		if (searchBar) {
			inputRef.current.focus();
		}
	}, [searchBar]);

	return (
		<div className={`search-bar-container ${searchBar ? "active" : ""}`}>
			<div className="field-wrapper">
				<input
					ref={inputRef}
					type="text"
					placeholder="Search..."
					onInput={(e) => {
						setSearchValue(e.target.value);
					}}
					onKeyUp={(e) => {
						if (e.key === "Enter") {
							handleButton();
						}
					}}
				/>
			</div>
			<div
				className={`search-button`}
				aria-label="Search"
				onClick={() => {
					handleButton();
				}}
			>
				<FontAwesomeIcon icon={searchBar ? (searchValue === "" ? faClose : faCheck) : faMagnifyingGlass} />
			</div>
		</div>
	);
};
