import { ApiRequest } from "./base";

const apiRoute = "payment/clearpay";

export const ClearpayApi = {
	async createCheckout() {
		let result = await ApiRequest(`${apiRoute}/create-checkout`, "POST");
		return result;
	}
};
