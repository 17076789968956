import React from "react";
import { Helmet } from "react-helmet";

import { useMetaStore } from "../stores/MetaStore";
import Config from "../Config";

const permanentStructuredData = Config.meta.structuredData;

export const Head = () => {
	const {
		image,
		canonical,
		structuredData,
		title,
		description,
		keywords
	} = useMetaStore(({
		image, canonical, structuredData, title, description, keywords
	}) => ({image, canonical, structuredData, title, description, keywords}));

	const structuredDataArray = Array.isArray(structuredData) ? structuredData : [structuredData];
	const structuredDataOutput = structuredData ? [...permanentStructuredData, ...structuredDataArray] : permanentStructuredData;

	return (
		<Helmet>
			<title>{title}</title>
			<meta
				name="description"
				content={description}
			/>

			<meta
				property="og:title"
				content={title}
			/>
			<meta
				property="og:description"
				content={description}
			/>

			<meta
				name="keywords"
				content={keywords}
			/>

			{image && (
				<meta
					property="og:image"
					content={image.replace(".local", ".co.uk")}
				/>
			)}

			{canonical && (
				<link
					rel="canonical"
					href={canonical}
				/>
			)}

			{structuredDataOutput.map((data, index) => (
				<script key={index} type="application/ld+json">
					{JSON.stringify(data)}
				</script>
			))}
		</Helmet>
	);
};

export default Head;
