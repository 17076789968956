import { faCheck, faPencil } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";

import "../assets/scss/components/_field.scss";

export const Field = ({ label = "Field Label", value = "", type = "text", minLength = 0, required = false, step = "false", onChange = () => {}, onToggleComplete = () => {}, isEditable = true, toggleEditing = false, ref = null, classes = "", autocomplete = "", ...props }) => {
	const CheckIsEditable = () => {
		if (!isEditable) return true;
		if (toggleEditing === true && toggledEditing !== true) return true;
		return false;
	};

	const [inputValue, setInputValue] = useState(value);

	const [toggledEditing, setToggledEditing] = useState(false);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	return (
		<div className={`field ${classes} ${toggleEditing ? "toggleable" : ""} ${toggleEditing && toggledEditing ? "toggled" : ""}`}>
			<input
				type={type}
				minLength={minLength}
				placeholder=" "
				required={required}
				onChange={(e) => {
					setInputValue(e.target.value);
					onChange(e);
				}}
				autoComplete={autocomplete}
				disabled={CheckIsEditable()}
				value={inputValue}
				aria-label={label}
				ref={ref}
				step={step}
				{...props}
			/>
			{toggleEditing && isEditable && (
				<div
					className="edit-button"
					onClick={(e) => {
						setToggledEditing((prev) => !prev);
						onToggleComplete(e);
					}}
				>
					<FontAwesomeIcon icon={toggledEditing ? faCheck : faPencil} />
				</div>
			)}
			<label>{label}</label>
		</div>
	);
};

export default Field;
