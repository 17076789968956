import React, { useEffect } from "react";
import Config from "../Config";

import { ClearpayApi } from "../api/ClearpayApi";
import { useCartStore } from "../stores/CartStore";

import "../assets/scss/components/_clearpay.scss";

import ClearpayLogo from "../assets/images/clearpay-logo.png";

export const Clearpay = () => {
	const { totals } = useCartStore(({ totals }) => {
		return { totals };
	});

	const [error, setError] = React.useState("");
	const [clearpayCheckout, setClearpayCheckout] = React.useState(false);

	const clearpayInit = async () => {
		const res = await ClearpayApi.createCheckout();
		console.log(res);

		if (!res?.payment?.result) {
			setError("Something went wrong on our end, please try again later or proceed to purchase with your card.");
			return;
		}

		const info = res?.payment?.result;
		if (info?.errors.length > 0) {
			//we have an error
			setError(res.errors.message);
			return;
		}

		setClearpayCheckout(true);

		setTimeout(() => {
			window.open(info.redirect_url, "_blank");
		});
	};

	return (
		totals.grandTotal &&
		totals.grandTotal > 0 &&
		Config.paymentMethodsEnabled.clearpayEnabled === true && (
			<div className="container">
				<div
					id="clearpay-widget-container"
					className="clearpay-widget-container"
				>
					{error}
					<button
						className="create-checkout btn"
						onClick={clearpayInit}
					>
						Pay With
						<div className="icon">
							<img
								src={ClearpayLogo}
								alt="Clearpay Logo"
								className="clearpay-logo"
							/>
						</div>
					</button>
					<span className="clearpay-terms">
						Instant approval decision - 4 interest-free payments of {Config.currency}
						{parseFloat(totals.grandTotal / 4).toFixed(2)}
					</span>
					{clearpayCheckout && (
						<div className="clearpay-checkout-modal">
							<img
								src={ClearpayLogo}
								alt="Clearpay Logo"
								className="clearpay-logo"
							/>
							<p>Your Clearpay checkout should open in a couple of seconds, to continue with your purchase using Clearpay proceed with the steps there. To cancel and pay by card instead click the close button below.</p>
							<div
								className="btn"
								onClick={() => {
									clearpayInit;
								}}
							>
								Didn't open? Click here to open Clearpay checkout
							</div>
							<div
								className="btn close-button"
								onClick={() => {
									setClearpayCheckout(false);
								}}
							>
								Close Clearpay Checkout
							</div>
						</div>
					)}
				</div>
			</div>
		)
	);
};

export default Clearpay;
