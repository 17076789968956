import { ApiRequest } from "./base";

const apiRoute = "login";

export const LoginApi = {
	async Login(email, password) {
		return ApiRequest(`${apiRoute}`, "POST", { email, password });
	},
	async ForgotPassword(email) {
		return ApiRequest(`${apiRoute}/forgotten-password`, "POST", { email });
	},
	async CheckPasswordToken(token) {
		return ApiRequest(`${apiRoute}/forgotten-password/${token}`, "GET");
	},
	async UpdatePassword(token, password) {
		return ApiRequest(`${apiRoute}/forgotten-password/${token}`, "POST", { password });
	},
	async GetSocialUrl(provider) {
		return ApiRequest(`${apiRoute}/social/${provider}`, "GET");
	},
	async SocialLogin(provider, code) {
		return ApiRequest(`${apiRoute}/social/${provider}/callback`, "POST", {
			code,
			return: true
		});
	}
};
