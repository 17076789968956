import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "../assets/scss/components/_full-review-section.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/free-solid-svg-icons";
import { ReviewSection } from "../components/ReviewSection";
import { ReviewsApi } from "../api/ReviewsApi";

import ReviewsLogo from "../assets/images/reviews.png";

export const FullReviewSection = ({ slides_per_row = { base: 1.1, md: 1.5 } }) => {
	const [reviews, setReviews] = useState([]);
	const [reviewStats, setReviewStats] = useState({});

	useEffect(() => {
		const getStoreReviews = async () => {
			const { reviews } = await ReviewsApi.getStoreReviews();
			if (reviews.reviews) {
				setReviews(reviews.reviews);
			}
			if (reviews.stats) {
				setReviewStats(reviews.stats);
			}
		};
		getStoreReviews();
	}, []);

	return (
		<section className="full-review-section">
			<div className="reviews-section-container">
				<div
					className="row align-items-center justify-content-center justify-content-lg-space-between"
					style={{
						"--gap-md": "10px"
					}}
				>
					<div className="col-5 col-md-3 reviews-io-section">
						<img
							src={ReviewsLogo}
							alt="Reviews IO Logo"
						/>
						<div className="review-stars">
							<span className="star">
								<FontAwesomeIcon icon={faStar} />
							</span>
							<span className="star">
								<FontAwesomeIcon icon={faStar} />
							</span>
							<span className="star">
								<FontAwesomeIcon icon={faStar} />
							</span>
							<span className="star">
								<FontAwesomeIcon icon={faStar} />
							</span>
							<span className="star">
								<FontAwesomeIcon icon={faStarHalf} />
							</span>
						</div>
						<div className="rating">
							{reviewStats?.average_rating ?? "4.7"} / 5 <br />
							{reviewStats?.total_reviews ? Math.floor(reviewStats?.total_reviews / 100) * 100 : "12,000"}+ Reviews
							<Link
								className="view-more-link"
								to="https://www.reviews.co.uk/company-reviews/store/123-flowers-co-uk"
								target="_blank"
								alt="123 Flowers Reviews on Reviews.io"
							>
								View More
							</Link>
						</div>
					</div>
					<div className="col-12 col-md-9 slider-section">
						<ReviewSection
							slides_per_row={slides_per_row}
							reviews={reviews}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};
