import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";

import { useUserStore } from "../stores/UserStore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";

export const AccountContainer = () => {
	const accountDropdownRef = useRef(null);
	const [accountDetails, setAccountDetails] = useState(false);

	const { userInfo, Logout, GetInfo } = useUserStore(({ userInfo, Logout, GetInfo }) => {
		return { userInfo, Logout, GetInfo };
	});

	useEffect(() => {
		GetInfo();
	}, [GetInfo]);

	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (accountDetails === true && accountDropdownRef.current && !accountDropdownRef.current.contains(e.target)) {
				setAccountDetails(false);
			}
		};

		document.addEventListener("click", handleOutsideClick, true);

		return () => {
			document.removeEventListener("click", handleOutsideClick, true);
		};
	}, [accountDetails]);

	return (
		<div
			className="account-container"
			ref={accountDropdownRef}
		>
			<div className="row align-items-center">
				<div
					className={`account-details ${accountDetails && "menu-open"}`}
					onClick={() => {
						setAccountDetails(!accountDetails);
					}}
				>
					<div className="text">
						<span>Hi, Welcome!</span>
						<strong>{userInfo && userInfo.billing_information ? userInfo.billing_information.name : "Sign In/Sign Up"}</strong>
					</div>

					<div className="dropdown-menu">
						{userInfo && !userInfo.id ? (
							<>
								<Link to="/login" state={{ redirect: location.pathname }}>
									<div className="dropdown-menu-item">Sign In</div>
								</Link>
								<Link to="/register">
									<div className="dropdown-menu-item">Sign Up</div>
								</Link>
							</>
						) : (
							<>
								<Link to="/account">
									<div className="dropdown-menu-item">Your Account</div>
								</Link>

								<Link to="/account/orders">
									<div className="dropdown-menu-item">Your Orders</div>
								</Link>

								<div
									className="dropdown-menu-item"
									onClick={() => {
										Logout();
									}}
								>
									Sign Out
								</div>
							</>
						)}
					</div>

					<FontAwesomeIcon icon={faChevronDown} />
				</div>
			</div>
		</div>
	);
};
