import React from "react";

import { ProductListing } from ".";

import "../assets/scss/components/_related-products.scss";

export const RelatedProducts = ({ info = [] }) => {
	return info?.length ? (
		<section className="related-products">
			<h3>Not what you're looking for? Why not check out:</h3>

			<div className="row justify-content-lg-space-between">
				{info.map((product, index) => {
					return (
						<ProductListing
							info={product}
							key={index}
							id={product.products_id}
							size={{ base: 6, md: 4 }}
							sort={index}
						/>
					);
				})}
			</div>
		</section>
	) : null;
};
