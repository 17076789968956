import "vite/modulepreload-polyfill";

import React from "react";
import ReactDOM from "react-dom/client";
import { PostHogProvider } from "posthog-js/react";

import "./assets/scss/base.scss";
import App from "./App";

import { useAppStore } from './stores/AppStore';

import Config from "./Config.js";

const posthogConfig = {
	api_host: Config.POSTHOG_API
};

// Make sure this is the first thing we do
const appStore = useAppStore.getState();
appStore.cacheVersionValidation();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<PostHogProvider
		apiKey={Config.POSTHOG_KEY}
		options={posthogConfig}
	>
		<App />
	</PostHogProvider>
);
