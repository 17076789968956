import { ApiRequest } from "./base";

const apiRoute = "cart";

export const CartApi = {
	async GetCart() {
		let { cart } = await ApiRequest(`${apiRoute}/`, "GET");
		return cart;
	},
	async GetTotals() {
		let { totals } = await ApiRequest(`${apiRoute}/totals`, "GET");
		return totals;
	},
	async AddToCart(info) {
		let { new_key, cart } = await ApiRequest(`${apiRoute}/add-product`, "POST", info);
		return { new_key, cart };
	},
	async AddPersonalisedCard(id) {
		return ApiRequest(`${apiRoute}/${id}/add-personalised-card`, "GET");
	},
	async RemoveFromCart(cartKey) {
		return ApiRequest(`${apiRoute}/remove/${cartKey}`, "POST");
	},
	async ClearCart() {
		return ApiRequest(`${apiRoute}/clear`, "POST");
	},
	async UpdateProductInCart(cartId, data) {
		data["full_cart_update"] = "Yes";

		return ApiRequest(`${apiRoute}/update/${cartId}`, "POST", data);
	},
	async ApplyDiscount(discountCode) {
		return ApiRequest(`${apiRoute}/apply-discount`, "POST", { coupon_code: discountCode });
	},
	async ApplyCredit(credit) {
		let response = await ApiRequest(`${apiRoute}/apply-credit`, "POST", { credit_amount: credit });
		return response;
	}
};
