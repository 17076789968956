import React from "react";

import { ProductOverlays, Placeholder, Image } from ".";

import "../assets/scss/components/_product-image.scss";

export const ProductImage = ({ image, overlayType = "", overlayValue = "", alt = "", lazy = false }) => {
	return (
		<div className="product-image row align-items-center">
			<Placeholder check={image}>
				<ProductOverlays
					type={overlayType}
					value={overlayValue}
				/>
				{image !== "" && (
					<Image
						src={image}
						alt={alt}
						width={400}
						loading={lazy ? "lazy" : "eager"}
					/>
				)}
			</Placeholder>
		</div>
	);
};
