import React from "react";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

import "../assets/scss/components/_breadcrumbs.scss";

export const Breadcrumbs = ({ productName }) => {
	return (
		<div className="breadcrumbs">
			<Link to="/">
				Home <FontAwesomeIcon icon={faChevronRight} />
			</Link>
			<Link to="/">
				Next Day Flowers <FontAwesomeIcon icon={faChevronRight} />
			</Link>
			<span>{productName ?? "    "}</span>
		</div>
	);
};
