import React from "react";

import { useMetaStore } from "../stores/MetaStore";

export const Seo = () => {
	const { h1, h2, footerContent } = useMetaStore(({ h1, h2, footerContent }) => {
		return {
			h1,
			h2,
			footerContent
		};
	});
	return (
		<div className="seo-content">
			<div className="container">
				{h1 && <h1>{h1}</h1>}
				{h2 && <h2>{h2}</h2>}
				{footerContent && <p dangerouslySetInnerHTML={{ __html: footerContent }}></p>}
			</div>
		</div>
	);
};
