import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../assets/scss/components/_tabbed-section.scss";

export const TabbedSection = ({ prefix = "", title, tabs }) => {
	const [activeTab, setActiveTab] = useState(0);

	return (
		<section className={`tabbed-section ${prefix && prefix + "-tabbed-section"}`}>
			<h3 className="section-title">{title}</h3>

			<div className="toggle-section">
				{tabs.map((tab, index) => (
					<div
						className={`toggle ${activeTab === index ? "active" : ""}`}
						key={tab.title}
						onClick={() => setActiveTab(index)}
					>
						<FontAwesomeIcon icon={tab.icon} /> {tab.title}
					</div>
				))}
			</div>
			<div className="content-section">
				{tabs.map((tab, index) => (
					<div
						className={`content ${activeTab === index ? "active" : ""}`}
						key={`${tab.title}_content`}
					>
						{Array.isArray(tab.content) ? (
							<React.Fragment>
								<strong>The {tab.title} I Need:</strong>
								<ol>
									{tab.content.map((item, i) => (
										<li
											className="content-item"
											key={i}
										>
											{item}
										</li>
									))}
								</ol>
							</React.Fragment>
						) : (
							<p>{tab.content}</p>
						)}
					</div>
				))}
			</div>
		</section>
	);
};
