import { ApiRequest } from "./base";

const apiRoute = "config";

export const ConfigApi = {
	async GetConfig() {
		let { session_id, promo_banner, peak_configs } = await ApiRequest(`${apiRoute}/`, "GET");
		return { session_id, promo_banner, peak_configs };
	},
};
