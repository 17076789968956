import React, { useEffect, useState } from "react";

import { useCategoriesStore } from "../stores/CategoriesStore";
import { useAppStore } from "../stores/AppStore";

import "../assets/scss/components/_category-sorting.scss";

export const CategorySorting = () => {
	const { sort, tags, availableSorts, availableTags, SetActiveTags, SetActiveSort } = useCategoriesStore(({ sort, tags, availableSorts, availableTags, SetActiveTags, SetActiveSort, activeSort }) => {
		return { sort, tags, availableSorts, availableTags, SetActiveTags, SetActiveSort };
	});

	const [activeTagsObject, setActiveTagsObject] = useState(tags);

	useEffect(() => {
		SetActiveTags(activeTagsObject);
	}, [activeTagsObject, SetActiveTags]);

	const UpdateSort = (e) => {
		SetActiveSort(e.target.value);
	};

	return (
		<section className="category-sorting">
			{Object.keys(availableTags).map((tag, index) => {
				return (
					<div
						className="category-sorting-section"
						key={index}
					>
						<strong>{tag}</strong>
						<div className="category-sorting-section-tags">
							{availableTags[tag].map((value, index) => {
								return (
									<div
										className={`category-sorting-tag ${Object.values(activeTagsObject[tag]).includes(value) ? "active" : ""}`}
										key={index}
										onClick={() => {
											let obj = { ...activeTagsObject };

											if (useAppStore.getState().isDebug) {
												console.log("CategorySorting.js - Active tags object", activeTagsObject);
											}

											if (Object.values(activeTagsObject[tag]).includes(value)) {
												var index = obj[tag].indexOf(value);
												if (index !== -1) {
													obj[tag].splice(index, 1);
												}
											} else {
												if (useAppStore.getState().isDebug) {
													console.log("CategorySorting.js - Add tag", value);
												}
												obj[tag].push(value);
											}
											setActiveTagsObject(obj);
										}}
									>
										{value}
									</div>
								);
							})}
						</div>
					</div>
				);
			})}

			<div className="field">
				<label htmlFor="category-sort">Sort By</label>
				<select
					name="category-sort"
					id="category-sort"
					className="category-sorting-section"
					onChange={(e) => {
						UpdateSort(e);
					}}
					value={sort}
				>
					{Object.keys(availableSorts).map((order, index) => {
						return (
							<option
								value={availableSorts[order]}
								key={index}
							>
								{availableSorts[order]}
							</option>
						);
					})}
				</select>
			</div>
		</section>
	);
};
