import React, { useState, useEffect, Suspense, lazy } from "react";
import { useLoaderData } from "react-router-dom";
import { useUrlStorage } from "../stores/UrlStore";

import { ProductPage } from "./ProductPage";
import { Category } from "./Category";
import { Static } from "./Static";

const Error404 = lazy(() => import("../pages/Error404"));

export const UrlHandler = () => {
	const params = useLoaderData();
	const [url, setUrl] = useState({});

	useEffect(() => {
		const getUrlData = async (params) => {
			try {
				const urlInfo = await useUrlStorage.getState().GetUrl(params);
				setUrl(urlInfo);
			} catch(error) {
				setUrl({ content_id: 0, content_type: "404" });
			}
		};

		getUrlData(params);
	}, [params]);

	if(typeof url.content_id === 'undefined') {
		return null;
	}

	switch (url.content_type) {
		case "product":
			return <ProductPage id={url.content_id ?? 0} />;
		case "category":
			return <Category id={url.content_id ?? 0} />;
		case "static_page":
			return <Static id={url.content_id ?? 0} />;
		case "404":
		default:
			return (
				<Suspense fallback={<span></span>}>
					<Error404 />
				</Suspense>
			);
	}
};

export default UrlHandler;
