import React, { useState, useRef, useEffect } from "react";
import { useRecordWebcam, ERROR_MESSAGES } from "./webcam";

import "../assets/scss/components/_record-a-message-section.scss";
import { RecordAMessageApi } from "../api/RecordAMessage";
import { useProductStore } from "../stores/ProductStore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faMicrophone, faArrowLeft, faStop, faPlay, faCheck, faTrashCan } from "@fortawesome/pro-regular-svg-icons";

import QR from "../assets/images/qr.gif";
import Record from "../assets/images/record.gif";
import Send from "../assets/images/send.gif";
import Slider from "./Slider";

const CAPTURE_OPTIONS_VIDEO = {
	audio: true,
	video: { facingMode: "user" }
};

const CAPTURE_OPTIONS_AUDIO = {
	audio: true,
	video: false
};

const RECORD_MAX_LENGTH = 30000;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const blobToBase64 = (blob) =>
	new Promise((resolve) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});

export const RecordAMessageSection = () => {
	const [showRecordAMessage, setShowRecordAMessage] = useState(false);
	const [recordingType, setRecordingType] = useState(false);
	const [trackStage, setTrackStage] = useState(0);

	return (
		<section className="record-a-message-section col-12">
			<span className="callout">New!</span>
			<strong className="section-title">Would you like to add on a FREE Audio or Video message?:</strong>
			<p>Record a video or audio message to send with your gift. You can record a message up to 30 seconds long and we will include it with the message card that you have chosen above.</p>

			<div className="record-a-message-container">
				<div
					className="record-a-message-track"
					style={{
						left: `${trackStage * -100}%`
					}}
				>
					<div className="how-it-works">
						<Slider
							slides={[
								{
									element: (
										<div className="how-it-works-step record">
											<img
												src={Record}
												alt="Record"
											/>
											<p>Record your message</p>
										</div>
									)
								},
								{
									element: (
										<div className="how-it-works-step send">
											<img
												src={Send}
												alt="Send"
											/>
											<p>We'll send it with your message card</p>
										</div>
									)
								},
								{
									element: (
										<div className="how-it-works-step qr">
											<img
												src={QR}
												alt="QR"
											/>
											<p>They scan the code and enjoy</p>
										</div>
									)
								}
							]}
							slides_per_row={{ base: 1, md: 3 }}
							slide_duration={3}
							indicators={true}
							autoplay={true}
						/>
						<button
							className="record-a-message-button"
							onClick={() => setTrackStage(1)}
						>
							Get Started
						</button>
					</div>
					<div className="recording-type">
						<div className="recording-type-options record-a-message-controls">
							<strong className="col-12">Select your recording type:</strong>
							<button
								className="back-button"
								onClick={() => {
									setTrackStage(0);
								}}
							>
								<FontAwesomeIcon icon={faArrowLeft} />
								Back
							</button>
							<button
								className={`recording-type-option ${recordingType === "video" ? "active" : ""}`}
								onClick={() => {
									setRecordingType("video");
									setTrackStage(2);
								}}
							>
								<FontAwesomeIcon icon={faVideo} />
								Video
							</button>
							<button
								className={`recording-type-option ${recordingType === "audio" ? "active" : ""}`}
								onClick={() => {
									setRecordingType("audio");
									setTrackStage(2);
								}}
							>
								<FontAwesomeIcon icon={faMicrophone} />
								Audio
							</button>
						</div>
					</div>
					<div className="actual-recording">
						{recordingType && (
							<RecordAMessage
								recordingType={recordingType}
								setRecordingType={setRecordingType}
								setTrackStage={setTrackStage}
							/>
						)}
					</div>
					<div className="message-complete">
						<p>Your message has been recorded and is ready to be sent with your gift.</p>
						<p>If you would like to start again please click below.</p>
						<button
							onClick={() => {
								setTrackStage(0);
							}}
						>
							Start Over
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};

const RecordAMessage = ({ recordingType, setRecordingType, setTrackStage }) => {
	const { UploadVideoMsg } = useProductStore(({ UploadVideoMsg }) => ({ UploadVideoMsg }));
	const { createRecording, openCamera, startRecording, stopRecording, downloadRecording, clearError, clearAllRecordings, activeRecordings, errorMessage } = useRecordWebcam({
		mediaTrackConstraints: recordingType === "video" ? CAPTURE_OPTIONS_VIDEO : CAPTURE_OPTIONS_AUDIO,
		mediaRecorderOptions: {
			audioBitsPerSecond: 128000,
			videoBitsPerSecond: 512000
		}
	});

	const [recording, setRecording] = useState(false);
	const [recordingInstance, setRecordingInstance] = useState(null);
	const [countDown, setCountDown] = useState(3000);
	const [timeoutInstance, setTimeoutInstance] = useState(null);
	const [recordedFile, setRecordedFile] = useState(null);

	const saveRecording = async () => {
		if (!recordedFile) {
			return;
		}
		const response = await UploadVideoMsg(recordingType, recordedFile);
	};

	useEffect(() => {
		const countDownInterval = (timeout) => {
			return new Promise((resolve) => {
				const interval = setInterval(() => {
					setCountDown((countDown) => countDown - 1000);
				}, 1000);

				setTimeout(() => {
					clearInterval(interval);
					resolve();
				}, timeout);
			});
		};

		const handleStartRecording = async () => {
			if (activeRecordings.length) {
				clearError();
				await clearAllRecordings();
			}

			await countDownInterval(countDown);

			const recordingInst = await createRecording();
			setRecordingInstance(recordingInst);

			await openCamera(recordingInst.id);

			await startRecording(recordingInst.id);

			setTimeoutInstance(
				setTimeout(() => {
					setRecording(false);
				}, RECORD_MAX_LENGTH)
			);
		};

		const handleStopRecording = async () => {
			if (timeoutInstance) {
				clearTimeout(timeoutInstance);
			}
			const file = await stopRecording(recordingInstance.id);
			if (recordingInstance.webcamRef.current) {
				recordingInstance.webcamRef.current.pause();
			}
			const base64 = await blobToBase64(file.blob);
			setRecordedFile(base64);
		};

		if (recording) {
			handleStartRecording();
		} else if (recordingInstance) {
			handleStopRecording();
		}

		return () => {
			if (recordingInstance) {
				handleStopRecording();
			}
		};
	}, [recording, recordingType]);

	return (
		<div className="record-a-message-options row">
			<div className="record-a-message-option col-12 col-md-12">
				<div className="record-a-message-container">
					{/* <select
						className="record-a-message-select"
						value={recordingType}
						onChange={(e) => setRecordingType(e.target.value)}
						hidden={activeRecordings.length}
					>
						<option value="video">Video</option>
						<option value="audio">Audio</option>
					</select> */}
					<div className="record-a-message-preview">
						{recording && countDown > 0 && <div className="countdown">{countDown / 1000}</div>}
						{activeRecordings.map((activeRecording) => (
							<div key={activeRecording.id}>
								{recordingType === "video" ? (
									<>
										{["INITIAL", "OPEN"].includes(activeRecording.status) && <div>Loading</div>}
										<video
											ref={activeRecording.webcamRef}
											autoPlay
											controls
											hidden={["STOPPED", "INITIAL", "OPEN"].includes(activeRecording.status)}
										/>
										<video
											ref={activeRecording.previewRef}
											controls
											hidden={["RECORDING", "INITIAL", "OPEN"].includes(activeRecording.status)}
										/>
									</>
								) : (
									<>
										{["INITIAL", "OPEN"].includes(activeRecording.status) && <div>Loading</div>}
										<audio
											ref={activeRecording.webcamRef}
											autoPlay
											controls
											hidden={["STOPPED", "INITIAL"].includes(activeRecording.status)}
										/>
										<audio
											ref={activeRecording.previewRef}
											controls
											hidden={["RECORDING", "INITIAL", "OPEN"].includes(activeRecording.status)}
										/>
									</>
								)}
							</div>
						))}
					</div>

					<div className="record-a-message-controls">
						<button
							className="back-button"
							onClick={() => setTrackStage(1)}
						>
							<FontAwesomeIcon icon={faArrowLeft} />
							Back
						</button>
						<button
							className="record-button"
							onClick={() => setRecording(!recording)}
						>
							<FontAwesomeIcon icon={recording ? faStop : faPlay} />
							{recording ? "Stop" : "Recording"}
						</button>
						<button
							className="delete-button"
							onClick={() => {
								setRecording(false);
								setRecordedFile(null);
								setTrackStage(0);
								clearAllRecordings();
							}}
							disabled={!recordedFile}
							style={{ display: !recordedFile ? "none" : "" }}
						>
							<FontAwesomeIcon icon={faTrashCan} />
							Delete
						</button>
						<button
							className="save-button"
							onClick={() => {
								saveRecording();
								setTrackStage(3);
							}}
							disabled={!recordedFile}
							style={{ display: !recordedFile ? "none" : "" }}
						>
							<FontAwesomeIcon icon={faCheck} />
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
