const worker = new Worker(new URL("../worker.js", import.meta.url), { type: "module" });

const resolvers = new Map();

worker.addEventListener("message", ({ data }) => {
	if (resolvers.has(data.id)) {
		if (data.error) {
			resolvers.get(data.id).reject(data.error);
		} else {
			resolvers.get(data.id).resolve(data);
		}
		resolvers.delete(data.id);
	}
});

// If there's an error in the worker, reject all pending requests
worker.addEventListener("error", (error) => {
	for (const [id, { reject }] of resolvers) {
		reject(error);
		resolvers.delete(id);
	}
});

async function ApiRequest(url, method, data = null) {
	return new Promise((resolve, reject) => {
		const id = location.protocol == "https:" ? crypto.randomUUID() : Math.random().toString(36).substring(7);
		worker.postMessage([id, url, method, data]);
		resolvers.set(id, { resolve, reject });
	});
}

export { ApiRequest };
