import { ApiRequest } from "./base";

const apiRoute = "register";

import { useAppStore } from "../stores/AppStore";

export const RegisterApi = {
	async Register(registrationData) {
		if (useAppStore.getState().isDebug) {
			console.log("RegisterApi.js - Registration data", registrationData);
		}

		return await ApiRequest(`${apiRoute}`, "POST", { ...registrationData, billing_info: { ...registrationData.billing_info } });
	}
};
