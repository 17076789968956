import React from "react";

import "../assets/scss/components/_category-special-info.scss";

import ReneTitle from "../assets/images/rene-title.png";
import RenePicture from "../assets/images/rene-picture.jpg?w=200";

export const CategorySpecialInfo = ({ specialName }) => {
	switch (specialName) {
		case "rene":
			return (
				<div
					className="category-special-info rene-special-info"
					style={{ order: 5 }}
				>
					<img
						src={ReneTitle}
						className="rene-title"
						alt="Rene Franken | His Story"
					/>
					<div className="description">
						<div className="row align-items-center justify-content-space-between">
							<div className="col-12 col-md-3 rene-img-container">
								<img
									src={RenePicture}
									alt="Rene Franken"
								/>
							</div>
							<div className="col-12 col-md-9 rene-quote">
								<strong>"Like a symphony of colour and fragrance, flowers have the ability to create an enchanting atmosphere and touch our deepest emotions. I'm delighted to offer my collection of luxurious flowers sourced from the finest regions of the world. This range represents a culmination of my life's work, and I'm excited to share its beauty and elegance with you. Welcome to a world of exquisite blooms that will elevate any occasion."</strong>
							</div>
						</div>

						<p>A lifetime dedicated to mastering true floristry craft, René and his family were one of the first growers of Lisianthus in the Netherlands, produced on his father’s flower farm. René travelled to many parts of the world, sourcing the most unique flowers that he imported to the UK, inspiring arrangements across many major suppliers, not only in the UK but across Europe. Flowers by René, brought exclusively to 123 Flowers, brings a decadent range of premium bouquets, based on his favourite arrangements from a lifetime in the floral industry.</p>
					</div>
				</div>
			);
		default:
			return null;
	}
};

export default CategorySpecialInfo;
