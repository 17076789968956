import { ApiRequest } from "./base";

const apiRoute = "subscriptions";

export const SubscriptionApi = {
    async getSubscriptions() {
        return ApiRequest(`${apiRoute}`, "GET");
    },
	async cancelSubscription(subscription_id) {
		return ApiRequest(`${apiRoute}/disable`, "POST", { subscription_id });
	},
    async pauseSubscription(subscription_id) {
        return ApiRequest(`${apiRoute}/pause`, "POST", { subscription_id });
    },
    async resumeSubscription(subscription_id) {
        return ApiRequest(`${apiRoute}/resume`, "POST", { subscription_id });
    }
};
