import React from "react";

import "../assets/scss/components/_website-banner.scss";

export const WebsiteBanner = ({ link, text, buttonText }) => {
	return (
		<a
			className="website-banner"
			href={link}
		>
			<span>{text}</span>

			<div className="btn-container">
				<div className="btn">{buttonText}</div>
			</div>
		</a>
	);
};
