import { ApiRequest } from "./base";

const apiRoute = "products";

export const ProductApi = {
	async getInfo(id) {
		return ApiRequest(`${apiRoute}/${id}`, "GET");
	},
	async getInfoBulk(ids = []) {
		return ApiRequest(`${apiRoute}/batch/${ids.join(",")}`, "GET");
	},
	async getRelated(id) {
		return ApiRequest(`${apiRoute}/suggestions/${id}`, "GET");
	},
	async getDeliveryMethods(id, date) {
		const res = await ApiRequest(`${apiRoute}/${id}/delivery-options`, "POST", {
			date: date
		});

		return res.delivery_options;
	}
};
