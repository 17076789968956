import React from "react";

import "../assets/scss/components/_product-choices.scss";

import { useProductStore } from "../stores/ProductStore";

export const ProductChoices = ({ setSecondStage, SetCurrentProductStep }) => {
	const { availableAddons, selectedAddons, selectedDeliveryDate, selectedDeliveryOption, selectedSize, messageCardText, messageCardType, printzwareId, videoMsg } = useProductStore((state) => state);

	const NavigateToSection = (containerClass) => {
		document.querySelector(containerClass).scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
	};

	return (
		<section className="product-choices">
			<div className="row column justify-content-space-between">
				<div className="choice size-choice">
					<div className="title">
						Selected Size
						<div
							className="change"
							onClick={() => {
								SetCurrentProductStep("prev");
								setSecondStage(false);
							}}
						>
							Change Size
						</div>
					</div>
					<div
						className="values"
						onClick={() => {
							SetCurrentProductStep("prev");
							setSecondStage(false);
						}}
					>
						<div className="value">{selectedSize}</div>
					</div>
				</div>

				{selectedAddons.length > 0 && (
					<div className="choice addon-choice">
						<div className="title">Selected Addons</div>
						<div
							className="values"
							onClick={() => {
								NavigateToSection(".addon-section");
							}}
						>
							{selectedAddons.map((addon, id) => {
								return (
									<div
										className="value"
										key={id}
									>
										{availableAddons[addon].pretty_name}
									</div>
								);
							})}
						</div>
					</div>
				)}

				{(selectedDeliveryDate instanceof Date || selectedDeliveryOption) && (
					<div className="choice delivery-choice">
						<div className="title">Delivery Options</div>
						<div
							className="values"
							onClick={() => {
								NavigateToSection(".delivery-section");
							}}
						>
							{selectedDeliveryDate instanceof Date && <div className="value">{selectedDeliveryDate.getDate() + " " + selectedDeliveryDate.toLocaleString("default", { month: "long" }) + " " + selectedDeliveryDate.getFullYear()}</div>}
							{selectedDeliveryOption && <div className="value">{selectedDeliveryOption.name.split("-")[0]}</div>}
						</div>
					</div>
				)}

				{messageCardType === "standard" && messageCardText && messageCardText !== "" && (
					<div className="choice message-choice">
						<div className="title">Message Card</div>
						<div
							className="values"
							onClick={() => {
								NavigateToSection(".message-card-section");
							}}
						>
							<div className="value">{messageCardText}</div>
							{videoMsg && <div className="value">Recorded Video Message</div>}
						</div>
					</div>
				)}

				{messageCardType === "personalised" && printzwareId && printzwareId !== "" && (
					<div className="choice message-choice">
						<div className="title">Message Card</div>
						<div
							className="values"
							onClick={() => {
								NavigateToSection(".message-card-section");
							}}
						>
							<div className="value">Personalised Message Card</div>
							{videoMsg && <div className="value">Recorded Video Message</div>}
						</div>
					</div>
				)}
			</div>
		</section>
	);
};
