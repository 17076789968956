import { ApiRequest } from "./base";

const apiRoute = "payment";

export const PaymentApi = {
	async CheckoutInvoice(invoiceReason) {
		const { payment } = await ApiRequest(`${apiRoute}/invoice/checkout`, "POST", {
            invoice_reason: invoiceReason
        });
		return payment;
	},
};
