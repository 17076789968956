import React, { useEffect, useState } from "react";
import { RouterProvider, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { RoutesComponent } from "./components/Routes";
import { DebugHandler } from "./components/DebugHandler";
import { LoadingAnimation } from "./components/LoadingAnimation";
import { Head } from "./components/Head";
import { Notifications } from "./components/Notifications";

import { useAppStore } from "./stores/AppStore";
import { useUserStore } from "./stores/UserStore";
import { useProductStore } from "./stores/ProductStore";
import { useCategoriesStore } from "./stores/CategoriesStore";

import { useUrlStorage } from "./stores/UrlStore";
import { useOrdersStore } from "./stores/OrdersStore";

// import TagManager from "react-server-side-gtm";
import Config from "./Config";
import { CookieBanner } from "./components/CookieBanner";

function gtag() {
	dataLayer.push(arguments);
}

const { GTM_ANALYTICS_ID, GTM_FB_PIXEL_ID, GTM_TIKTOK_PIXEL_ID, GTM_BING_TAG_ID, GTM_ADWORDS_CONVERSION_ID, GTM_ADWORDS_CONVERSION_LABEL, GTM_MOUSEFLOW_ID, GTM_AWIN_MERCHANT_ID, GTM_KELKOO_ID } = Config;

window.dataLayer = window.dataLayer || [];

const GA4PageData = () => {
	const location = useLocation();
	const { userInfo } = useUserStore((state) => state);

	const getPageType = () => {
		return location.pathname === "/" ? "home" : location.pathname;
	};

	const getOrderDetails = () => {
		if (getPageType() === "cart/payment-success") {
			const orders = useOrdersStore.getState().orders;
			if (orders.length > 0) {
				const order = orders[0];
				return {
					grandTotal: Number(order.grand_total),
					deliveryTotal: Number(order.delivery_total),
					couponCode: order.coupon_code
				};
			}
		}
		return { grandTotal: null };
	};

	const GA4PageData = () => {
		const pageType = getPageType();
		const { grandTotal } = getOrderDetails();
		const siteLayout = window.innerWidth > 768 ? "desktop" : "mobile";

		const gtmConfigs = {
			analytics_id: GTM_ANALYTICS_ID,
			fb_pixel_id: GTM_FB_PIXEL_ID,
			tiktok_pixel_id: GTM_TIKTOK_PIXEL_ID,
			bing_tag_id: GTM_BING_TAG_ID,
			adwords_conversion_id: GTM_ADWORDS_CONVERSION_ID,
			adwords_conversion_label: GTM_ADWORDS_CONVERSION_LABEL,
			mouseflow_id: GTM_MOUSEFLOW_ID,
			kelkoo_id: GTM_KELKOO_ID
		};

		// Page Data
		const pageData = {
			event: "page_data",
			page_type: pageType,
			site_layout: siteLayout,
			...gtmConfigs,
			account_configs: gtmConfigs,
			user: {
				user_id: userInfo.id || 0,
				logged_in_state: userInfo.id ? "logged_in" : "logged_out"
			},
			transactionTotal: grandTotal
		};

		// Remove null or empty properties
		const filteredPageData = Object.fromEntries(Object.entries(pageData).filter(([key, value]) => value !== null && value !== undefined && value !== ""));

		// TagManager.dataLayer({ dataLayer: filteredPageData });
		window.dataLayer.push(filteredPageData);
	};

	const GA4PageInit = () => {
		const gtmData = {
			event: "config_init",
			analytics_id: GTM_ANALYTICS_ID,
			fb_pixel_id: GTM_FB_PIXEL_ID,
			tiktok_pixel_id: GTM_TIKTOK_PIXEL_ID,
			bing_tag_id: GTM_BING_TAG_ID,
			adwords_conversion_id: GTM_ADWORDS_CONVERSION_ID,
			adwords_conversion_label: GTM_ADWORDS_CONVERSION_LABEL,
			mouseflow_id: GTM_MOUSEFLOW_ID,
			awin_merchant_id: GTM_AWIN_MERCHANT_ID,
			kelkoo_id: GTM_KELKOO_ID
		};
		
		window.dataLayer.push(gtmData);
		
		// Consent Manager
		gtag("consent", "default", {
			ad_storage: "granted",
			analytics_storage: "granted",
			ad_user_data: "granted",
			ad_personalization: "granted"
		});
		window.dataLayer.push({ event: "default_consent" });
	};

	useEffect(() => {
		GA4PageInit();
	}, []);

	useEffect(() => {
		GA4PageData();
	}, [location]);
};

const Root = () => {
	const mainContent = React.useRef(null);
	const mainFooter = React.useRef(null);

	const location = useLocation();
	const navigate = useNavigate();

	try {
		GA4PageData();
	} catch (error) {
		console.error("Error settings GA4 data - ", error);
	}

	useEffect(() => {
		if (location.pathname && location.pathname === "/" && location.search && location.search.includes("?content")) {
			//fix old links
			const content = location.search.replace("?content=", "");
			console.log(content);
			navigate(`/${content}`);
		}

		if (mainContent && mainContent.current) {
			mainContent.current.scrollTo({ top: 0 });
		}
		const HTMLElement = document.querySelector("html");
		if (HTMLElement) {
			HTMLElement.scrollTo({ top: 0 });
		}
		useCategoriesStore.getState().ResetActiveTags();
		useCategoriesStore.getState().ResetCategoryInfo();
		useProductStore.getState().ResetProductStore();
		useUserStore.getState().GetInfo();
	}, [location]);

	return (
		<ErrorBoundary
			fallback={
				<LoadingAnimation
					link={false}
					bodyOverride={"It seems that we're having a few issues loading the website right now, please try again in a few minutes."}
				/>
			}
			onError={(error) => typeof newrelic !== "undefined" && newrelic?.noticeError(error)}
		>
			<div className={`App`}>
				<div
					id="main-header"
					className="main-header"
				>
					<Head />
					<Header />
				</div>
				<div
					id="main-content"
					className="main-content"
					ref={mainContent}
				>
					{/* <OldSiteSwitch /> */}
					<DebugHandler />
					<div className="page-content">
						<Outlet key={location.pathname} />
					</div>
					<CookieBanner />
					<div
						id="main-footer"
						className="main-footer"
						ref={mainFooter}
					>
						<Footer />
					</div>
				</div>
			</div>
		</ErrorBoundary>
	);
};

function App() {
	const { init, sessionId } = useAppStore();

	useEffect(() => {
		init();
		useUrlStorage.getState().GetNavigationLinks();
	}, [init]);

	return sessionId === null ? (
		<LoadingAnimation
			link={false}
			bodyOverride={"It seems that we're having a few issues loading the website right now, please try again in a few minutes."}
		/>
	) : (
		<RouterProvider router={RoutesComponent({ RootElement: Root })} />
	);
}

export default App;
