import axios from "axios";

import Config from "../Config";

// Intercept the response to automatically throw returned errors
axios.interceptors.response.use(
    ({ data }) => {
        if (data.success === false) {
            throw new Error(data.errors.join(' '));
        }
        return data.response;
    },
    (error) => {
        return Promise.reject(error);
    },
);
  

async function ApiRequest(url, method, data = null) {
	const { apiUrl, apiVersion } = Config;

	const instance = axios.create({
		baseURL: apiUrl,
		withCredentials: true
	});

	let dataToPass = new FormData();

	if (method === "POST" && data) {
		Object.entries(data).forEach(([key, value]) => {
			if (typeof value === "object" && !Array.isArray(value)) {
				Object.entries(value).forEach(([nestedKey, nestedValue]) => {
					dataToPass.append(`${key}[${nestedKey}]`, nestedValue);
				});
			} else if (Array.isArray(value)) {
				value.forEach((v) => dataToPass.append(`${key}[]`, v));
			} else {
				dataToPass.append(key, value);
			}
		});
	}

	let options = {
		url: `${apiUrl}/${apiVersion}/${url}`,
		method: method
	};

	if (data) {
		options.data = method === "POST" ? dataToPass : data.data;
	}

	try {
		const response = await instance.request(options);
		if (response.data) {
			// If the API returned an error, throw it so we can catch it below
			if(response?.data?.errors?.length > 0) {
				throw new Error(response.data.errors.join(","));
			}
			return { ...response.data.response };
		} else {
			return {};
		}
	} catch (error) {
		// console.error(`API request failed: ${error}`);
		// Throw it again so we can catch it elsewhere
		throw new Error(error.message);
	}
}

export { ApiRequest };
