import React from "react";

import "../assets/scss/components/_cookie-banner.scss";

export const CookieBanner = () => {
	const [cookieBanner, setCookieBanner] = React.useState(true);

	const handleAccept = () => {
		localStorage.setItem("cookieBanner", "accepted");
		setCookieBanner(false);
	};

	return (
		!localStorage.getItem("cookieBanner") &&
		cookieBanner && (
			<div className="cookie-banner">
				<p>We use cookies to improve your experience on our site. By using our site you consent cookies.</p>
				<button
					className="btn"
					onClick={() => {
						handleAccept();
					}}
				>
					Accept
				</button>
			</div>
		)
	);
};
