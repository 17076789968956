import React, { useEffect } from "react";

import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useUserStore } from "../stores/UserStore";

export const RequireLogin = ({
	children,
	loginRequired = true,
	redirectPage = "/login"
}) => {
	const { userInfo } = useUserStore(({ userInfo }) => ({ userInfo }));
	const navigate = useNavigate();
	const location = useLocation();
	const [params] = useSearchParams();

	if (params.has("redirect")) {
		redirectPage = params.get("redirect");
	}
	if (location.state?.redirect) {
		redirectPage = location.state.redirect;
	}
	if(redirectPage[0] !== "/") {
		redirectPage = `/${redirectPage}`;
	}

	useEffect(() => {
		if(loginRequired === true) {
			if(!userInfo || !userInfo.id) {
				return navigate(redirectPage, { state: { redirect: location.pathname } });
			}
		} else {
			if(userInfo.id) {
				return navigate(redirectPage, { state: { redirect: location.pathname } });
			}
		}
	}, [userInfo]);

	return children;
};