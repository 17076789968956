import { ApiRequest } from "./base";

const apiRoute = "user";

export const UserApi = {
	async getInfo(id) {
		return ApiRequest(`${apiRoute}`, "GET");
	},
	async update(data) {
		return ApiRequest(`${apiRoute}/update`, "POST", data);
	}
};
