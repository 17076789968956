import React, { useEffect } from "react";

import "../assets/scss/components/_product-sizes.scss";
import { useProductStore } from "../stores/ProductStore";

export const ProductSizes = () => {
	const { info, selectedSize, sizes, GetSizes, SetSize } = useProductStore((state) => state);

	useEffect(() => {
		GetSizes();
	}, [GetSizes]);

	return (
		<section className="size-section-container">
			<strong className="section-title">Choose Your {info.product_type === "Subscription" ? "Frequency:" : "Size:"}</strong>
			<div className="size-section">
				{/* <span className="stem-label">{info.product_type === "Subscription" ? "Frequency" : "Stem Count"}</span> */}
				{sizes && sizes.length > 0 ? (
					<>
						{sizes.map((size, index) => (
							<div
								key={index}
								className={`row justify-content-space-between align-items-center size-choice ${selectedSize === size.size ? "active" : ""}`}
								onClick={() => SetSize(size.size)}
							>
								<div className="col-fit">
									<div className="stem-count active">{size.size_text.substr(0, 1)}</div>
								</div>
								<div className="col-fluid">
									{size.size_text} {parseFloat(size.price) !== 0 && `(+£${parseInt(size.price)})`}
									{size.size_text === "Medium" && <span className="medium-shown medium-shown-desk">Medium bouquet is shown in pictures</span>}
								</div>
							</div>
						))}
					</>
				) : (
					<div className={`row justify-content-space-between align-items-center size-choice active`}>
						<div className="col-fit">
							<div className="stem-count active">14</div>
						</div>
						<div className="col-fluid">One Size</div>
					</div>
				)}

				<div className="medium-shown medium-shown-mob col-12">Medium bouquet is shown in pictures</div>
			</div>
		</section>
	);
};
