import React from "react";
import { Slider } from ".";

import "../assets/scss/components/_page-header.scss";

export const PageHeader = ({ title, body, slides = [], classes = "" }) => {
	return (
		<section className={`page-header ${classes}`}>
			<div className="header-body-container">
				<div className="container">
					<div className="header-description">
						<strong>{title}</strong>
						<p>{body}</p>
					</div>
				</div>
			</div>

			{slides.length > 0 && (
				<div className="points-container">
					<div className="container">
						<Slider
							slides={slides}
							slides_per_row={{ base: 1, md: slides.length }}
							autoplay={true}
						/>
					</div>
				</div>
			)}
		</section>
	);
};
