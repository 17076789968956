import React, { useEffect, useRef } from "react";

import { useCartStore } from "../stores/CartStore";
import { faShoppingBasket } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MiniCart } from "./MiniCart";

import { useLocation } from "react-router-dom";

export const MiniCartContainer = () => {
	const miniCartRef = useRef(null);
	const { cart, GetCart, ToggleMiniCart, miniCart } = useCartStore((state) => state);

	useEffect(() => {
		GetCart();
	}, [GetCart]);

	const location = useLocation();

	useEffect(() => {
		if (location.pathname.includes("/cart")) {
			ToggleMiniCart(false);
		}
	}, [ToggleMiniCart, location]);

	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (miniCart === true && miniCartRef.current && !miniCartRef.current.contains(e.target)) {
				ToggleMiniCart(false);
			}
		};

		document.addEventListener("click", handleOutsideClick, true);

		return () => {
			document.removeEventListener("click", handleOutsideClick, true);
		};
	}, [ToggleMiniCart, miniCart]);

	return (
		<div
			className="mini-cart-container"
			ref={miniCartRef}
		>
			<div
				className={`basket-button ${cart && Object.keys(cart).length > 0 && "indicator-shown"}`}
				style={{
					"--count": cart && (Object.keys(cart).length ?? 0)
				}}
				onClick={() => {
					ToggleMiniCart();
				}}
			>
				<FontAwesomeIcon icon={faShoppingBasket} />
			</div>
			{cart && (
				<MiniCart
					status={miniCart}
					cart={cart}
				/>
			)}
		</div>
	);
};
