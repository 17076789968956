import { create } from "zustand";
import { log } from "./helpers";

import { persist, devtools } from "zustand/middleware";

const useNotificationsStore = create(
	persist(
		devtools(
			log((set, get) => ({
				notifications: [],
				async AddNotification(notification) {
					set({ notifications: [...get().notifications, notification] });
				},
				async RemoveNotification(key) {
					//key is the index of the notification in regards to the arry
					const notifications = get().notifications;
					notifications.splice(key, 1);

					set({ notifications: notifications });
				}
			}))
		),
		{
			name: "orders-storage"
		}
	)
);

export { useNotificationsStore };
