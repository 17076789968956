import React from "react";

import "../assets/scss/components/_footer.scss";

import { Link } from "react-router-dom";

import Config from "../Config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter, faTiktok, faPinterest } from "@fortawesome/free-brands-svg-icons";
import { Seo } from "./Seo";

window._klOnsite = window._klOnsite || [];

export const Footer = () => {
	const { siteName, logo, socials } = Config;

	return (
		<div className="footer">
			<div className="central-footer">
				<div className="container">
					<div className="row justify-content-space-between">
						<div className="col-12 col-md-6">
							<strong>Popular Categories</strong>

							<div className="links">
								<div className="row">
									<div className="col-12 col-md-6">
										<Link to="/next-day-flowers">Next Day Flowers</Link>
										<Link to="/birthday-flowers">Birthday Flowers</Link>
										<Link to="/anniversary-flowers">Anniversary Flowers</Link>
										<Link to="/get-well-soon-flowers">Get Well Soon Flowers</Link>
									</div>
									<div className="col-12 col-md-6">
										<Link to="/sympathy-flowers">Sympathy Flowers</Link>
										<Link to="/new-baby-flowers">New Baby Flowers</Link>
										<Link to="/thank-you-flowers">Thank You Flowers</Link>
										{/* <Link to="/best-selling-flowers">Best Selling Flowers Flowers</Link> */}
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-2">
							<strong>Company Links</strong>
							<Link to="/delivery-information">Delivery Information</Link>
							<Link to="/about-us">About Us</Link>
							<Link to="/contact-us">Contact Us</Link>
							<Link
								to="https://www.reviews.co.uk/company-reviews/store/123-flowers-co-uk"
								target="_blank"
							>
								Reviews
							</Link>
						</div>
						<div className="col-12 col-md-2">
							<strong>Legal</strong>
							<Link to="/terms">Terms & Conditions</Link>
							<Link to="/privacy-policy">Privacy Policy</Link>
						</div>

						<div className="col-12 col-md-2">
							<strong>Socials</strong>
							<div className="row">
								<Link
									to={socials.facebook}
									target="_blank"
									rel="noreferrer"
									aria-label="Facebook"
								>
									<FontAwesomeIcon
										size="2xl"
										icon={faFacebook}
									/>
								</Link>
								<Link
									to={socials.instagram}
									target="_blank"
									rel="noreferrer"
									aria-label="Instagram"
								>
									<FontAwesomeIcon
										size="2xl"
										icon={faInstagram}
									/>
								</Link>
								<Link
									to={socials.twitter}
									target="_blank"
									rel="noreferrer"
									aria-label="Twitter"
								>
									<FontAwesomeIcon
										size="2xl"
										icon={faTwitter}
									/>
								</Link>
								<Link
									to={socials.tiktok}
									target="_blank"
									rel="noreferrer"
									aria-label="TikTok"
								>
									<FontAwesomeIcon
										size="2xl"
										icon={faTiktok}
									/>
								</Link>
								<Link
									to={socials.pinterest}
									target="_blank"
									rel="noreferrer"
									aria-label="Pinterest"
								>
									<FontAwesomeIcon
										size="2xl"
										icon={faPinterest}
									/>
								</Link>
							</div>
							<div className="row">
								<button
									type="button"
									className="btn"
									onClick={() => {
										window._klOnsite.push(["openForm", "XfAYBi"]);
									}}
								>
									Sign Up To Special Offers
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="lower-footer">
				<div className="container">
					<hr />
					<div className="row align-items-center justify-content-space-between">
						<div className="col-12 col-md-1">
							<img
								src={logo}
								alt={siteName}
							/>
						</div>
						<div className="col-12 col-md-3 copy">
							&copy; {new Date().getFullYear()} {siteName}, All Rights Reserved
						</div>
					</div>
				</div>
			</div>

			<Seo />
		</div>
	);
};
