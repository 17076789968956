import React, { useMemo } from "react";
import "../assets/scss/components/_step-tracker.scss";
import { useProductStore } from "../stores/ProductStore";

export const StepTracker = ({ productSteps, setSecondStage }) => {
	const { currentProductStep } = useProductStore((state) => state);

	const heightPercentage = useMemo(() => {
		return ((currentProductStep + 1) / productSteps.length) * 100 + "%";
	}, [currentProductStep, productSteps.length]);

	return (
		<div className="step-tracker-container">
			<div
				className="step-tracker"
				style={{
					"--current": currentProductStep + 1,
					"--total": productSteps.length,
					"--height": heightPercentage
				}}
			></div>
			<div className="steps">
				{productSteps.map((step, index) => {
					return (
						<div
							className={`step ${currentProductStep === index && "active"}`}
							key={step}
							onClick={() => {
								useProductStore.setState({ currentProductStep: index });
								setSecondStage(index === 0 ? false : true);
							}}
						>
							{step}
						</div>
					);
				})}
			</div>
		</div>
	);
};
