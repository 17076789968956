import { ApiRequest } from "./base";

const apiRoute = "orders";

export const OrdersApi = {
	async getHistory(limit = 25) {
		try {
			const { total_orders, orders } = await ApiRequest(`${apiRoute}/history`, "POST", {
				limit: limit,
				page: 1,
				order: "asc"
			});

			return { total_orders, orders };
		} catch (error) {
			console.error(`Failed to get order history: ${error}`);
			return { total_orders: 0, orders: [] };
		}
	},
	async getOrderDetails(orderId) {
		try {
			const { order } = await ApiRequest(`${apiRoute}/${orderId}`, "GET");
			return order;
		} catch (error) {
			console.error(`Failed to get order details: ${error}`);
			return {};
		}
	},
	async sendInvoice(orderId) {
		try {
			await ApiRequest(`${apiRoute}/request-invoice/${orderId}`, "POST");
			return true;
		} catch (error) {
			console.error(`Failed to send invoice: ${error}`);
			return false;
		}
	}
};
