import React, { useState, useEffect, useRef } from "react";
import "../assets/scss/components/_placeholder.scss";

export const Placeholder = (props) => {
	const [ready, setReady] = useState(false);
	const childRef = useRef(null);

	useEffect(() => {
		if (childRef.current && props.check !== undefined && props.check !== "" && props.check !== false) {
			setReady(true);
		}
	}, [props.check]);

	return (
		<div
			ref={childRef}
			className={`placeholder ${!ready ? "active" : ""} ${props.class ? props.class : ""}`}
			style={{
				...props.style,
				"--placeholder-width": props.width
			}}
		>
			{props.children}
		</div>
	);
};
