import React, { Suspense, lazy } from "react";
import "../assets/scss/components/_delivery-section.scss";

const DeliveryDate = lazy(() => import("./DeliveryDate").then((module) => ({ default: module.DeliveryDate })));
const DeliveryForm = lazy(() => import("./DeliveryForm").then((module) => ({ default: module.DeliveryForm })));

export const DeliverySection = ({ showDeliveryOptions = true, showFullAddress, setShowFullAddress }) => {
	return (
		<section className="delivery-section">
			<Suspense fallback={<div>Loading...</div>}>
				<DeliveryDate showDeliveryOptions={showDeliveryOptions} />
			</Suspense>
			<br />
			<strong className="section-title">Fill in the recipient details:</strong>
			<p>Start adding in the delivery postcode below and we will do the rest.</p>
			<Suspense fallback={<div>Loading...</div>}>
				<DeliveryForm
					showFullAddress={showFullAddress}
					setShowFullAddress={setShowFullAddress}
				/>
			</Suspense>
		</section>
	);
};
