import React, { useEffect } from "react";

import { useCategoriesStore } from "../stores/CategoriesStore";

import { OutputProducts, LoadingAnimation, HomeUpsell } from ".";

export const HomeProducts = () => {
	const { homepageInfo, GetHomepage } = useCategoriesStore(({ homepageInfo, GetHomepage }) => ({ homepageInfo, GetHomepage }));

	useEffect(() => {
		GetHomepage();
	}, [GetHomepage]);

	return (
		<div className="home-products">
			{homepageInfo.length ? (
				<>
					<div className="featured">
						<div className="container">
							<OutputProducts
								products={homepageInfo[0]}
								loadingAnimation={false}
								lazy={true}
								aboveFold={true}
							/>
						</div>
					</div>

					<HomeUpsell />

					<div className="remaining">
						<div className="container">
							<OutputProducts
								products={homepageInfo[1]}
								loadingAnimation={false}
								lazy={true}
							/>
						</div>
					</div>
				</>
			) : (
				<LoadingAnimation />
			)}
		</div>
	);
};
