import { create } from "zustand";
import { log } from "./helpers";

import { persist, devtools } from "zustand/middleware";

import { OrdersApi } from "../api";

const useOrdersStore = create(
	persist(
		devtools(
			log((set, get) => ({
				orders: {},
				orderDetails: {},
				orderTotals: 0,
				async GetOrders() {
					try {
						const result = await OrdersApi.getHistory();

						if (result.total_orders && result.total_orders > 0) {
							set({ orders: result.orders, orderTotals: result.total_orders });
						} else {
							set({ orders: {}, orderTotals: 0 });
						}
					} catch (error) {
						console.error("Failed to fetch orders:", error);
					}
				},
				async GetOrderDetails(orderId) {
					try {
						const orderDetails = await OrdersApi.getOrderDetails(orderId);
						set({ orderDetails: orderDetails });
					} catch (error) {
						console.error("Failed to fetch order details:", error);
						set({ orderDetails: {} });
					}
				}
			}))
		),
		{
			name: "orders-storage"
		}
	)
);

export { useOrdersStore };
