import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { UrlApi } from "../api";

const useUrlStorage = create(
	persist(
		devtools((set, get) => ({
			navigationLinks: [],
			async GetUrl(params) {
				const urlInfo = await UrlApi.GetUrl(params);
				return urlInfo;
			},
			async GetNavigationLinks() {
				const res = await UrlApi.GetNavigationLinks();
				set({ navigationLinks: res });
			}
		})),
		{
			name: "url-storage"
		}
	)
);

export { useUrlStorage };
