import React from "react";

import Flame from "./../assets/images/fire-small.gif";
import Sale from "./../assets/images/shopping-discount-small.gif";

import "../assets/scss/components/_product-overlays.scss";

export const ProductOverlays = ({ type = "", value = "" }) => {
	return type && value ? (
		<div className="overlays-container">
			<div className="overlays">
				<div className="overlay-track">
					{type === "sellingFast" && (
						<div className="selling-fast">
							<img
								src={Flame}
								alt="Bonfire"
								width="16px"
								height="22px"
							/>
							Selling Fast!
						</div>
					)}

					{type === "sale" && (
						<div className="sale">
							<img
								src={Sale}
								alt="Sale icon"
								width="22px"
								height="22px"
							/>
							{value.toFixed(0)}% Off!
						</div>
					)}
				</div>
			</div>
		</div>
	) : (
		<></>
	);
};
