import { ApiRequest } from "./base";

const apiRoute = "url";

export const UrlApi = {
	async GetUrl(path) {
		const slug = `${path.type}/${path.item || ""}`;
		let { content_type, content_id } = await ApiRequest(`${apiRoute}/${slug}`, "GET");
		return { content_type, content_id };
	},
	async GetNavigationLinks() {
		let { navigation } = await ApiRequest(`categories/navigation`, "GET");
		return navigation;
	}
};
